<template>
    <b-field class="upload avatar">
        <b-upload
                @input="onUploadMedia"
        >
            <a>
                <img :src="image" alt="Avatar">
            </a>
        </b-upload>

        <b-modal :active.sync="isComponentModalActive"
                 has-modal-card full-screen :can-cancel="false">

            <modal-croper-image
                :img-src="imgSrc"
                v-model="image"
            ></modal-croper-image>
        </b-modal>
    </b-field>
</template>

<script>
    import ModalCroperImage from "./ModalCroperImage";
    export default {
        name: "UploadAvatar",
        components: {ModalCroperImage},
        data() {
            return {
                file: null,
                isComponentModalActive: false,
                imgSrc: ''
            }
        },
        props: {
            apiUrl: String,
            method: {
                type: String,
                default: 'Post'
            },
            image: {
                type: String,
                default: '/img/user.png'
            }
        },
        watch: {
            image() {
                this.$auth.fetch();
            }
        },
        methods: {
            onUploadMedia(file) {
                this.storeFile(file, {}, 'files')
                .then(data => {
                    let file = data.data || {};
                    this.imgSrc = file.file
                    this.isComponentModalActive = true;
                });
            }
        }
    }
</script>

<style scoped>

</style>