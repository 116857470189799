<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ $t('user.avatar.crop') }}
            </p>
        </header>
        <section class="modal-card-body">
            <vue-cropper
                    ref="cropper"
                    :aspect-ratio="1"
                    :src="imgSrc"
                    alt="Source Image"
                    :check-cross-origin="false"
            ></vue-cropper>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                {{ $t('user.avatar.cancel') }}
            </button>
            <button class="button is-primary" @click="onSaveImage" type="button">
                {{ $t('user.avatar.save') }}
            </button>
        </footer>
    </div>
</template>

<script>
    export default {
        name: "ModalCroperImage",
        props: {
            imgSrc: String,
            val: String
        },
        methods: {
            onSaveImage() {
                let file =
                    this.$refs.cropper.getCroppedCanvas().toBlob(el => {
                        this.storeFile(el, {}, 'profile/avatar')
                            .then(data => {
                                this.$emit('input', data);
                                this.$parent.close();
                            });
                    });
            }
        }
    }
</script>

<style scoped>

</style>