<template>
    <div class="main-card">
        <div class="left-side">
            <h2 class="subtitle">
                {{ $t('user.general_data') }}
            </h2>
            <form>
                <b-field
                        :label="$t('user.attributes.name')"
                        :type="errors.name?'is-danger':''"
                        :message="errors.name"
                >
                    <b-input
                            v-model="user.name"
                            :placeholder="$t('user.placeholders.name')"
                    ></b-input>
                </b-field>

                <b-field
                        :label="$t('user.attributes.email')"
                        :type="errors.email?'is-danger':''"
                >
                    <b-input
                            type="email"
                            v-model="user.email"
                            :placeholder="$t('user.placeholders.email')"
                    ></b-input>
                </b-field>

                <b-field
                        :label="$t('user.attributes.password')"
                        :type="errors.password?'is-danger':''"
                >
                    <b-input
                            password-reveal
                            v-model="user.password"
                            :placeholder="$t('user.placeholders.password')"
                    ></b-input>
                </b-field>

                <b-field
                        :label="$t('user.attributes.password_confirmation')"
                        :type="errors.password_confirmation?'is-danger':''"
                >
                    <b-input
                            password-reveal
                            v-model="user.password_confirmation"
                            :placeholder="$t('user.placeholders.password_confirmation')"
                    ></b-input>
                </b-field>
            </form>

        </div>
        <div class="right-side">
            <div class="header">
                <h3>{{ $t('user.attributes.avatar') }}</h3>
            </div>

            <upload-avatar
                    :image="user.avatar"
                    api-url="profile/avatar"
            ></upload-avatar>
            <div class="field">
                <p class="control">
                    <button type="submit" class="button is-success">
                        {{ $t('user.profile.update.action') }}
                    </button>
                </p>
            </div>

        </div>
    </div>
</template>

<script>
    import UploadAvatar from "../components/utils/UploadAvatar";
    export default {
        name: "Profile",
        components: {UploadAvatar},
        data() {
            return {
                errors: {}
            }
        },
        computed: {
            user() {
                return this.$auth.user() || {};
            }
        }
    }
</script>

<style scoped>

</style>